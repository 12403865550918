
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import { PageType } from '@elendi/util-types';
import { ErrorPage } from '@elendi/ui-errors';
const Page404 = () => {
    const { t } = useTranslation();
    return (<ErrorPage h1={t('common:error.404.page-not-found')} title={t('common:error.404.title')} message={t('common:error.404.this-page-doesnt-exist-try-searching-for-something-else')} pageType={PageType.ENTERPRISE_USER} statusCode={404}/>);
};
export default Page404;

    async function __Next_Translate__getStaticProps__195fbd985f9__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195fbd985f9__ as getStaticProps }
  